import React, { useEffect } from 'react';
import { styled, Theme } from '@material-ui/core/styles';
import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import { useSelector } from 'src/redux/store';
import MenuBarClient from './components/MenuBar/MenuBarClient';
import ModalFeedback from './components/Modals/ModalFeedback';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

export default function App() {
  const roomState = useRoomState();
  const userRole = useSelector((state) => state.userProfile.userProfile);
  const modalOpen = useSelector((state) => state.modalOpen.openModal);
  const [open, setOpen] = React.useState(false);
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  const handleOpenModal = () => {
    setOpen(true);
  }

  const handelCloseModal = () => {
    setOpen(false);
  }


  useEffect(() => {
    if (modalOpen === true) {
      handleOpenModal();
    }
  }, [modalOpen])


  return (
    <Container style={{ height }}>
      {roomState === 'disconnected' ? (
        !modalOpen && <PreJoinScreens />
      ) : (
        <Main>
          <ReconnectingNotification />
          {/* <RecordingNotifications /> */}
          <MobileTopMenuBar />
          <Room />
          {userRole === 'client'
            ? (
              <MenuBarClient />
            )
            : (
              <MenuBar />
            )
          }
        </Main>
      )}
      {userRole === 'client' && <ModalFeedback open={open} onClose={handelCloseModal} />}
    </Container>
  );
}
