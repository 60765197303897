import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/redux/store';

// Define a type for the slice state
interface UserState {
  userProfile: string;
  expedientId: string;
  tokenId: string;
  userName: string;
}

// Define the initial state using that type
const initialState: UserState = {
  userProfile: 'client',
  expedientId: '',
  tokenId: '',
  userName: '',
};

export const slice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    assingUserRole: (state: UserState, action: PayloadAction<string>) => {
      state.userProfile = action.payload;
    },
    assingExpedientId: (state: UserState, action: PayloadAction<string>) => {
      state.expedientId = action.payload;
    },
    assingTokenId: (state: UserState, action: PayloadAction<string>) => {
      state.tokenId = action.payload;
    },
    assingUserName: (state: UserState, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
  },
});

export const { reducer } = slice;

export const assingUserRoleAction = (value: string): AppThunk => async (dispatch) : Promise<void> => {
  dispatch(slice.actions.assingUserRole(value));
};

export const assingExpedientIdAction = (value: string): AppThunk => async (dispatch) : Promise<void> => {
  dispatch(slice.actions.assingExpedientId(value));
};

export const assingTokenIdAction = (value: string): AppThunk => async (dispatch) : Promise<void> => {
  dispatch(slice.actions.assingTokenId(value));
};

export const assingUserNameAction = (value: string): AppThunk => async (dispatch) : Promise<void> => {
  dispatch(slice.actions.assingUserName(value));
};

export default slice;
