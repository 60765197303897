import React from 'react';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import { useTranslation } from 'react-i18next';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { IconButton, Tooltip } from '@material-ui/core';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const [t] = useTranslation('global');
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <Tooltip placement="top" title={isAudioEnabled ? t('header.title12') : t('header.title14') }>
      <span>
        <IconButton
          className={props.className}
          onClick={toggleAudioEnabled}
          disabled={!hasAudioTrack || props.disabled}
          data-cy-audio-toggle
        >
          {isAudioEnabled ? <MicIcon fontSize="default" /> : <MicOffIcon fontSize="default" />}
        </IconButton>
      </span>
    </Tooltip>
  );
}
