import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useSelector } from 'src/redux/store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const userRole = useSelector(state => state.userProfile.userProfile);
  const [hasRoomName, setHasRoomName] = useState(roomName ? true : false);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setRoomName(roomName);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  useEffect(() => {
    // Obtén el pathname de la URL actual
    const pathname = window.location.pathname;

    // Separa el pathname en segmentos
    const segments = pathname.split('/');

    // Verifica si el segundo segmento es igual a 'room' y si hay al menos tres segmentos en total
    if (segments[1] === 'room' && segments.length >= 3) {
      setHasRoomName(true);
    } else {
      setHasRoomName(false);
    }
  }, []);

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {t('header.title1')}
      </Typography>
      <Typography variant="body1">{hasRoomName ? t('header.title2') : t('header.title3')}</Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {hasRoomName && (
            <>
              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-user-name">
                {t('header.title4')}
                </InputLabel>
                <TextField
                  id="input-user-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                  disabled={userRole !== 'client'}
                />
              </div>
              {/* <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-room-name">
                  Inspección
                </InputLabel>
                <TextField
                  disabled
                  autoCapitalize="false"
                  id="input-room-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={roomName}
                  onChange={handleRoomNameChange}
                />
              </div> */}
            </>
          )}
        </div>
        {hasRoomName && (
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!name || !roomName}
              className={classes.continueButton}
            >
              {t('header.title5')}
            </Button>
          </Grid>
        )}
      </form>
    </>
  );
}
