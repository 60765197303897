import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { Provider } from 'react-redux';
import { store, persistor } from 'src/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import LoadingScreen from 'src/components/LoadingScreen';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_es from 'src/translations/es/global.json';
import global_en from 'src/translations/en/global.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <I18nextProvider i18n={i18next}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <App />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </I18nextProvider>
  );
};

export const ReactApp = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Provider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <Router>
            <AppStateProvider>
              <Switch>
                <PrivateRoute exact path="/">
                  <VideoApp />
                </PrivateRoute>
                <PrivateRoute path="/room/:URLRoomName/:URLRole/:LanguageApp/:Token?/:nameAdjuster?">
                  <VideoApp />
                </PrivateRoute>
                {/* <Route path="/login">
                  <LoginPage />
                </Route> */}
                <Redirect to="/" />
              </Switch>
            </AppStateProvider>
          </Router>
        </PersistGate>
      </Provider>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>
);

ReactDOM.render(<ReactApp />, document.getElementById('root'));
