import { combineReducers } from '@reduxjs/toolkit';
import { reducer as counterReducer } from 'src/redux/slices/counter';
import { reducer as userProfileReducer } from 'src/redux/slices/user';
import { reducer as videoCallReducer } from 'src/redux/slices/videocall';
import { reducer as modelOpenreducer } from 'src/redux/slices/modal';
import { feedbackApi } from 'src/services/feedbackService';
import { pokemonApi } from 'src/services/pokemonService';
import { videocallApi } from 'src/services/videocallService';

const appReducer = combineReducers({
  counter: counterReducer,
  userProfile: userProfileReducer,
  videoCall: videoCallReducer,
  modalOpen: modelOpenreducer,
  [pokemonApi.reducerPath]: pokemonApi.reducer,
  [videocallApi.reducerPath]: videocallApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
});

export type AppReducer = ReturnType<typeof appReducer>;

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  return appReducer(state, action);
};

export const resetAppAction = () => (dispatch) => {
  dispatch({ type: 'RESET_APP' });
};

export type RootReducer = ReturnType<typeof rootReducer>;
export default rootReducer;
