import React, { useCallback, useRef } from 'react';

import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const [t] = useTranslation('global');

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Tooltip placement="top" title={!hasVideoInputDevices ? t('header.title16') : isVideoEnabled ? t('header.title13') : t('header.title15')}>
      <span>
        <IconButton
          className={props.className}
          onClick={toggleVideo}
          disabled={!hasVideoInputDevices || props.disabled}
        >
          {isVideoEnabled ? <VideocamIcon fontSize="default" /> : <VideocamOffIcon fontSize="default" />}
        </IconButton>
      </span>
    </Tooltip>
  );
}
