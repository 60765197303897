import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, TextField, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { useCreateFeedbackMutation } from 'src/services/feedbackService';
import { Toaster, toast } from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'src/redux/store';
import { openModalAction } from 'src/redux/slices/modal';
import { useTranslation } from 'react-i18next';

interface IPropDialog {
  open: boolean;
  onClose: () => void;
}
const ModalFeedback: FC<IPropDialog> = props => {
  const { open, onClose } = props;
  const [value, setValue] = React.useState<number | null>(0);
  const [text, setText] = React.useState('');
  const expedientId = useSelector(state => state.userProfile.expedientId);
  const [valueField, setValueField] = React.useState('');
  const [error, setError] = React.useState('');
  const [createFeedback] = useCreateFeedbackMutation();
  const [openInnerDialog, setOpenInnerDialog] = React.useState(false);
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  const handleOpenInnerDialog = () => {
    setOpenInnerDialog(true);
  };

  const handleCloseInnerDialog = () => {
    setOpenInnerDialog(false);
    setValue(0);
    onClose();
    dispatch(openModalAction(false));
  };

  const handleCreateFeedback = async () => {
    if (valueField.trim() === '') {
      setError(t('header.title24'));
      return;
    } else {
      try {
        const response = await createFeedback({
          body: { score: value, comment: text, expedientId: expedientId },
        }).unwrap();
        handleOpenInnerDialog();
      } catch (e) {
        toast.error(e.data.message);
      }
      setValueField('');
      setError('');
    }
  };

  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          {' '}
          <Typography variant="h5" align="center">
            {t('header.title24')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" marginTop={4} flexDirection={'column'}>
            <Box display="flex" justifyContent="center">
              <Rating
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  setValueField('exist');
                }}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              {error && <Typography>{error}</Typography>}
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" marginTop={4} flexDirection="column">
            <Box>
              <Typography>{t('header.title25')}</Typography>
            </Box>
            <Box>
              <TextField fullWidth variant="outlined" multiline minRows={4} onChange={e => setText(e.target.value)} />
            </Box>
          </Box>
          <Dialog open={openInnerDialog}>
            <DialogContent style={{ textAlign: 'center' }}>
              <h2>{t('header.title27')}</h2>
              <Button onClick={handleCloseInnerDialog} color="primary" variant="contained">
                {t('header.title5')}
              </Button>
            </DialogContent>
          </Dialog>
        </DialogContent>
        <Box justifyContent="center" display="flex" marginTop={4} marginBottom={4}>
          <Box>
            <Button onClick={handleCreateFeedback} color="primary" variant="outlined" size="large">
              {t('header.title26')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default ModalFeedback;
