import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'src/redux/store';
import { openModalAction } from 'src/redux/slices/modal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#6366F1',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const { room } = useVideoContext();
  const dispatch = useDispatch();
  const userRole = useSelector(state => state.userProfile.userProfile);

  const handleClickRoom = () => {
    room!.disconnect();
    {
      userRole === 'client' && dispatch(openModalAction(true));
    }
  };

  return (
    <>
      <Button onClick={handleClickRoom} className={clsx(classes.button, props.className)} data-cy-disconnect>
        {t('header.title22')}
      </Button>
    </>
  );
}
