import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    data: null,
    notistackContent: null,
    inspectionClientPk: null,
    newScreenshot: null,
};

const slice = createSlice({
    name: 'videoCall',
    initialState,
    reducers: {

        addScreenshot(state, action) {
            const { data } = action.payload;
            state.data = data;
        },
        addNewScreenshot(state, action) {
            const data = action.payload;
            state.newScreenshot = data;
        },
        getInspectionData(state, action) {
            const { data } = action.payload;
            state.data = data;
        },
        setMessage(state, action) {
            const { message, variant } = action.payload;
            state.notistackContent = {
                message: message,
                variant: variant
            };
        },
        setInspectionClientPk(state, action) {
            const inspectionClientPk = action.payload;
            state.inspectionClientPk = inspectionClientPk;
        },
        cleanMessage(state) {
            state.notistackContent = null;
        },
        cleanNewScreenshot(state) {
            state.newScreenshot = null;
        },

    }

})

export const reducer = slice.reducer;


export const addScreenshotAction = (data) => async (dispatch) => {
    dispatch(slice.actions.addNewScreenshot(data));
};


export const cleanMessageAction = () => (dispatch) => {
    dispatch(slice.actions.cleanMessage());
};

export const cleanNewScreenshotAction = () => (dispatch) => {
    dispatch(slice.actions.cleanNewScreenshot());
};

export const setInspectionClientPkAction = (inspectionPk) => (dispatch) => {
    dispatch(slice.actions.setInspectionClientPk(inspectionPk));
};

export default slice;
