import React, { useEffect, useRef, useState, useCallback } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from './Menu/Menu';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden, IconButton, styled, Tooltip } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useSelector, useDispatch } from 'src/redux/store';
import { WEBSOCKET_ENDPOINT } from 'src/constants';
import { io } from 'socket.io-client';
import { isValidObject, isValidValue } from 'src/utils/validation';
import { captureVideoFrame } from 'src/utils/captureScreenshot';
import useLocalAudioToggle from 'src/hooks/useLocalAudioToggle/useLocalAudioToggle';
import useFlipCameraToggle from 'src/hooks/useFlipCameraToggle/useFlipCameraToggle';
import { usePosition } from 'use-position';
import { baseURL } from 'src/constants';
import { addScreenshotAction, cleanNewScreenshotAction } from 'src/redux/slices/videocall';
import { useInterval } from 'usehooks-ts';
import { useAppState } from 'src/state';
import CollaborationViewIcon from '@material-ui/icons/AccountBox';
import GridViewIcon from '@material-ui/icons/Apps';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#ffffff',
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBarClient() {
  const [t] = useTranslation('global');
  const watch = true;
  const classes = useStyles();
  const dispatch = useDispatch();
  const newScreenshot = useSelector(state => state.videoCall.newScreenshot);
  const expedientId = useSelector(state => state.userProfile.expedientId);
  const roomState = useRoomState();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { toggleFacingMode, newFacingMode } = useFlipCameraToggle();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const isReconnecting = roomState === 'reconnecting';
  const participants = useParticipants();
  const socket = useRef(null);
  const [incommingMessage, setIncommingMessage] = useState(null);
  const lastClickTimeRef = useRef(0);
  const { latitude, longitude } = usePosition(watch, { enableHighAccuracy: true });
  const { setIsGalleryViewActive, isGalleryViewActive } = useAppState();

  useEffect(() => {
    try {
      socket.current = io(WEBSOCKET_ENDPOINT);

      socket.current.on('new-message', newMessage => {
        const { message } = newMessage;
        setIncommingMessage(message);
      });
    } catch (error) {}
    // CLEAN UP THE EFFECT
    return () => {
      if (isValidObject(socket.current)) socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    try {
      const msg = {
        type: '_isFrontCamera',
        room: expedientId,
        data: newFacingMode,
      };

      if (!isValidObject(socket.current)) return;
      socket.current.emit('new-message', msg);
    } catch (_error) {
      console.log(_error);
    }
  }, [toggleFacingMode]);

  useEffect(() => {
    try {
      const msg = {
        type: '_isAudioEnable',
        room: expedientId,
        data: isAudioEnabled,
      };

      if (!isValidObject(socket.current)) return;
      socket.current.emit('new-message', msg);
    } catch (_error) {
      console.log(_error);
    }
  }, [isAudioEnabled]);

  useInterval(() => {
    const msg = {
      type: '_isLocation',
      room: expedientId,
      data: {
        latitude: isValidValue(latitude) ? latitude.toFixed(6) + '' : '',
        longitude: isValidValue(longitude) ? longitude.toFixed(6) + '' : '',
      },
    };

    if (!isValidObject(socket.current)) return;
    socket.current.emit('new-message', msg);
  }, 40000);

  // receive messages
  useEffect(() => {
    if (isValidObject(incommingMessage)) {
      if (incommingMessage.room === expedientId) {
        switch (incommingMessage.type) {
          case 'screenshot':
            takeSnapshot(incommingMessage.token);
            break;
          case 'toggleClientCameras':
            FlipCameraMode();
            break;
          case 'muteClient':
            toggleAudioEnabled();
            break;
          default:
            break;
        }
      }
      setIncommingMessage(null);
    }
  }, [incommingMessage]);

  useEffect(() => {
    try {
      if (isValidObject(newScreenshot)) {
        const msg = {
          type: '_isScreenshot',
          room: expedientId,
          data: {
            image: newScreenshot,
            latitude: isValidValue(latitude) ? latitude.toFixed(6) + '' : 0.0,
            longitude: isValidValue(longitude) ? longitude.toFixed(6) + '' : 0.0,
          },
        };

        if (!isValidObject(socket.current)) return;
        socket.current.emit('new-message', msg);
      }
      dispatch(cleanNewScreenshotAction());
    } catch (_error) {
      console.log(_error);
    }
  }, [newScreenshot]);

  const postData = (url, queryParams, bodyData, bearerToken) => {
    const headers = {
      // 'Content-Type': 'multipart/form-data',
      // Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    };
    const queryString = new URLSearchParams(queryParams).toString();
    const fetchOptions = {
      method: 'POST',
      headers,
      body: bodyData,
    };
    const apiUrl = `${url}/api/v1/videocall_photo/${expedientId}?${queryString}`;
    return fetch(apiUrl, fetchOptions)
      .then(response => response.json())
      .then(data => {
        dispatch(addScreenshotAction(data?.data));
      })
      .catch(error => {
        console.error(error); // handle errors
      });
  };

  const takeSnapshot = async (token: string) => {
    const name = localParticipant.identity;
    const videoEl = document.querySelector(`[data-cy-participant="${name}"] video`); // Replace 'content' with the ID of the element you want to screenshot
    if (videoEl !== null && videoEl !== undefined) {
      const { blob } = captureVideoFrame(videoEl);
      const formData = new FormData();
      // const blobData = dataURItoBlob(dataUri);
      formData.append('videocall_photo', blob);
      const latitudePhoto = isValidValue(latitude) ? latitude.toFixed(6) + '' : 0.0;
      const longitudePhoto = isValidValue(longitude) ? longitude.toFixed(6) + '' : 0.0;
      postData(
        baseURL,
        { label: '', longitude: longitudePhoto, latitude: latitudePhoto },
        formData,
        token
      );
    }
  };

  const FlipCameraMode = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 200) {
      lastClickTimeRef.current = Date.now();
      //@ts-ignore
      toggleFacingMode();
    }
  }, [toggleFacingMode]);

  return (
    <>
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              {/* <Typography
                color="textPrimary"
                variant="body1"
              >
                {`Rol: ${userRole}`}
              </Typography> */}
              <Typography variant="body1">
                {participants.length + 1} {t('header.title19')}{participants.length ? 's' : ''}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              <Tooltip placement="top" title={isGalleryViewActive ? t('header.title20') : t('header.title21')}>
                <IconButton
                  data-cy-screenshoot
                  onClick={() => {
                    setIsGalleryViewActive(isGallery => !isGallery);
                  }}
                >
                  {isGalleryViewActive ? (
                    <CollaborationViewIcon style={{ fill: '#707578', width: '0.9em' }} />
                  ) : (
                    <GridViewIcon style={{ fill: '#707578', width: '0.9em' }} />
                  )}
                </IconButton>
              </Tooltip>
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
              <Hidden smDown>
                <Menu />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <EndCallButton />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
