import { createApi } from '@reduxjs/toolkit/query/react';
import axiosInstanceQuery from 'src/lib/axiosInstanceQuery';
import { IResponseGeneric, INewPhotoVideoCall, Item  } from 'src/types';
import { encodeQuery } from 'src/utils/encodeQuery';

const API_VIDEOCALL = '/api/v1/videocall_photo';

export const videocallApi = createApi({
  baseQuery: axiosInstanceQuery({ baseUrl: API_VIDEOCALL }),
  reducerPath: 'videocallApi',
  tagTypes: ['videocall'],
  endpoints: (build) => ({
    uploadNewPhotoVideoCall: build.mutation<IResponseGeneric<Item>, INewPhotoVideoCall>({
      query: (data) => ({
        url: `/${data.expedientId}`,
        method: 'post',
        params: encodeQuery(data.query),
        body: data.videoCallPhoto,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }),
      // invalidatesTags: ['videocall']
    }),
  }),
});

export const {
  useUploadNewPhotoVideoCallMutation,
} = videocallApi;
