import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from './Menu/Menu';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { Typography, Grid, Hidden, IconButton, styled, Tooltip } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useSelector } from 'src/redux/store';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CameraFrontIcon from '@material-ui/icons/CameraFront';
import CameraRearIcon from '@material-ui/icons/CameraRear';
import MusicOffIcon from '@material-ui/icons/MusicOff';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import { isValidObject } from 'src/utils/validation';
import { WEBSOCKET_ENDPOINT, baseURLFrontend } from 'src/constants';
import { io } from 'socket.io-client';
import { toast, Toaster } from 'react-hot-toast';
import { useAppState } from 'src/state';
import CollaborationViewIcon from '@material-ui/icons/AccountBox';
import GridViewIcon from '@material-ui/icons/Apps';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#ffffff',
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

export default function MenuBar() {
  const classes = useStyles();
  const expedientId = useSelector(state => state.userProfile.expedientId);
  const tokenId = useSelector(state => state.userProfile.tokenId);
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  // const { room } = useVideoContext();
  const participants = useParticipants();
  const socket = useRef(null);
  const [newPhoto, setNewPhoto] = useState<boolean>(false);
  const [newToast, setNewToast] = useState<string>('');
  const [cameraClient, setCameraClient] = useState<string>('environment');
  const [micClient, setMicClient] = useState<boolean>(false);
  const { setIsGalleryViewActive, isGalleryViewActive } = useAppState();
  const [t] = useTranslation('global');

  useEffect(() => {
    try {
      const clientSocket = io(WEBSOCKET_ENDPOINT);
      socket.current = clientSocket;
      socket.current.on('new-message', newMessage => {
        const { message } = newMessage;

        if (message.room === expedientId && message.type === '_isScreenshot') {
          setNewPhoto(false);
          setNewToast('');
          toast.dismiss(newToast);
          if (message?.data) {
            window.parent.postMessage(message, baseURLFrontend);
            toast.success('Imagen Guardada');
          }
        }
        if (message.room === expedientId && message.type === '_isLocation') {
          if (message?.data) {
            window.parent.postMessage(message, baseURLFrontend);
          }
        }
        if (message.room === expedientId && message.type === '_isAudioEnable') {
          if (message) {
            setMicClient(message.data);
          }
        }
        if (message.room === expedientId && message.type === '_isFrontCamera') {
          if (message) {
            setCameraClient(message.data);
          }
        }
      });
    } catch (error) {
      setNewPhoto(false);
      toast.success('No se tomó la imagen');
      toast.dismiss(newToast);
      setNewToast('');
    }
    // CLEAN UP THE EFFECT
    return () => {
      if (isValidObject(socket.current)) {
        socket.current.disconnect();
        toast.dismiss(newToast);
      }
    };
  }, []);

  const takeSnapshot = () => {
    try {
      const toastId = toast.loading('Capturando...');
      setNewPhoto(true);
      setNewToast(toastId);
      const msg = {
        type: 'screenshot',
        room: expedientId,
        token: tokenId,
      };
      if (!isValidObject(socket.current)) return;
      socket.current.emit('new-message', msg);
    } catch (error) {
      //console.log(error);
      setNewPhoto(false);
      toast.success('No se tomó la imagen');
      toast.dismiss(newToast);
    }
  };

  const toggleClientCameras = () => {
    try {
      const msg = {
        type: 'toggleClientCameras',
        room: expedientId,
      };
      if (!isValidObject(socket.current)) return;
      socket.current.emit('new-message', msg);
    } catch (error) {
      //console.log(error);
    }
  };

  const muteClient = () => {
    try {
      const msg = {
        type: 'muteClient',
        room: expedientId,
      };
      if (!isValidObject(socket.current)) return;
      socket.current.emit('new-message', msg);
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Typography variant="body1">
                {participants.length + 1} {t('header.title19')}{participants.length ? 's' : ''}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              <Tooltip placement="top" title={isGalleryViewActive ? t('header.title20') : t('header.title21')}>
                <IconButton
                  data-cy-screenshoot
                  onClick={() => {
                    setIsGalleryViewActive(isGallery => !isGallery);
                  }}
                >
                  {isGalleryViewActive ? (
                    <CollaborationViewIcon style={{ fill: '#707578', width: '0.9em' }} />
                  ) : (
                    <GridViewIcon style={{ fill: '#707578', width: '0.9em' }} />
                  )}
                </IconButton>
              </Tooltip>
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              <Tooltip placement="top" title={t('header.title28')}>
                <span>
                  <IconButton
                    data-cy-screenshoot
                    onClick={takeSnapshot}
                    disabled={isReconnecting || !(participants.length > 0)}
                  >
                    <AddAPhotoIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip placement="top" title={t('header.title29')}>
                <span>
                  <IconButton
                    data-cy-screenshoot
                    onClick={toggleClientCameras}
                    disabled={isReconnecting || !(participants.length > 0)}
                  >
                    {cameraClient === 'user' ? <CameraRearIcon /> : <CameraFrontIcon />}
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip placement="top" title={t('header.title30')}>
                <span>
                  <IconButton
                    data-cy-screenshoot
                    onClick={muteClient}
                    disabled={isReconnecting || !(participants.length > 0)}
                  >
                    {micClient ? <MusicNoteIcon /> : <MusicOffIcon />}
                  </IconButton>
                </span>
              </Tooltip>
              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
              <Hidden smDown>
                <Menu />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <EndCallButton />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
