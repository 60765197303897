import { createApi } from '@reduxjs/toolkit/query/react';
import axiosInstanceQuery from 'src/lib/axiosInstanceQuery';
import { IRequestFeedback, IResponseFeedback, IResponseGeneric } from 'src/types';

const API_FEEDBACK = '/api/v1/feedback';

export const feedbackApi = createApi({
  baseQuery: axiosInstanceQuery({ baseUrl: API_FEEDBACK }),
  reducerPath: 'feedbackApi',
  tagTypes: ['feedback'],
  endpoints: (build) => ({
    createFeedback: build.mutation<IResponseGeneric<IResponseFeedback>, IRequestFeedback>({
      query: (data) => ({
        url: '',
        method: 'post',
        body: data.body,
      }),
      invalidatesTags: ['feedback']
    }),
  }),
});

export const {
  useCreateFeedbackMutation
} = feedbackApi;
