export const captureVideoFrame = (video) => {
    const canvas = document.createElement('canvas');
    const width = video.videoWidth;
    const height = video.videoHeight;

    
    canvas.width = width;
    canvas.height = height;

    canvas.getContext('2d').drawImage(video, 0, 0);

    const dataUri = canvas.toDataURL('image/jpeg', 1.0);
    const data = dataUri?.split(',')[1];
    const mimeType = dataUri?.split(';')[0].slice(5);

    const bytes = window.atob(data);
    const buf = new ArrayBuffer(bytes.length);
    const arr = new Uint8Array(buf);

    for (let i = 0; i < bytes.length; i++) {
        arr[i] = bytes.charCodeAt(i);
    }

    const blob = new Blob([arr], { type: mimeType });
    return { blob: blob, dataUri: dataUri,  width: width, height: height};
}



export const saveBlob = (blob, filename) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    //@ts-ignore
    a.style = 'display: none';

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

