import { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import Box from '@material-ui/core/Box'
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: 'background.paper',
      minHeight: '100%'
    }
  })
);

const LoadingScreen: FC = () => {
  const classes = useStyles();
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Box className={classes.container} />
  );
};

export default LoadingScreen;
