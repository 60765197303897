import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    openModal: false,
};

const slice = createSlice({
    name: 'modalOpen',
    initialState,
    reducers: {

        openModal(state, action) {
            state.openModal = action.payload;
        },

    }

})

export const reducer = slice.reducer;


export const openModalAction = (data) => async (dispatch) => {
    dispatch(slice.actions.openModal(data));
};

export default slice;
